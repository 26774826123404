/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "./variables/variables";

.bg-image-login-portal {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 52.4%,
      #000000 100%
    ),
    // url(../images/contest.jpg);
    url(../images/admin-portal-img.jpg);
  position: relative !important;
  min-height: 100vh !important;
}

.login-card {
  max-width: 90% !important;
  top: auto !important;

  .card {
    height: 300px;
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.15);
    cursor: pointer;
    color: white;
    font-size: x-large;

    .card-body {
      display: contents;
    }

    &:hover {
      border: 1px solid $primary;
      font-size: 40px;
    }
  }
}

.login-portal {
  .form-check-input:not(:checked) {
    background-color: $primary;
  }
}

.card-bg-height {
  min-height: 65vh;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  //background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52.4%, #000000 100%), url(../images/contest.jpg);
  position: relative !important;
  width: 100%;
  color: white !important;

  .overlay-bg {
    backdrop-filter: blur(5px) saturate(180%);
    -webkit-backdrop-filter: blur(5px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.15);
    min-height: 65vh !important;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 65vh;
    padding: 20px;

    h1,
    h3 {
      color: white;
    }
  }
}

.cursor-hover {
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.query-loading {
  min-height: 100vh !important;
  z-index: 10000000000 !important;
  max-width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.query-loading-modal-content {
  background: transparent !important;
  box-shadow: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.query-loading-spinner {
  height: 6rem !important;
  width: 6rem !important;
}

.c-date-picker {
  padding: 0.571rem 1rem;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  background: inherit;
  //border-color: inherit !important;
}

.back-icon {
  position: absolute;
  top: 35px;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.profile-logo {
  width: 280px;
  height: 280px;
}

.width-200 {
  width: 200px;
  word-wrap: normal;
}

.w-30 {
  width: 30%;
}

.tabs-sticky {
  top: 100px;
}

@media only screen and (max-width: 475px) {
  .w-75 {
    width: auto !important;
  }
}

.image-slider-card {
  position: relative;
  cursor: pointer;

  .remove-slider-image {
    position: absolute;
    float: right;
    display: none;
    justify-content: end;
    width: 95%;
    top: 5px;
    z-index: 100000 !important;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    overflow: hidden;

    .remove-slider-image {
      display: flex;
    }
  }
}

.slider-images-span {
  //padding: 10px;
  border: 1px solid #d8d6de;
  width: 100%;
  border-radius: 5px;
  display: flex !important;
}

.inner-span-style {
  /*  margin: 10px !important; */
  padding: 5px 10px !important;
  height: 30px;

  &.border--right {
    cursor: pointer;
    border-right: 1px solid #d8d6de;

    &:hover {
      background-color: #d8d6de;
    }

    display: block !important;
    width: max-content;
  }
}

.css-1rhbuit-multiValue {
  background-color: $secondary !important;
  color: white !important;
}

select.sc-fzoyTs.eMoCCA option {
  background: inherit !important;
}

.height-dvh {
  min-height: 75dvh;
}

.text-fade-in-out {
  color: $primary;
  margin-top: 10px;
  padding-top: 10px;
  animation: fade-in-out 2s ease-in-out infinite;
  font-size: x-large;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.max-height-300 {
  max-height: 300px !important;
  overflow-y: scroll !important;
}

::-webkit-scrollbar {
  height: 3px;
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary !important;
  border-radius: 5px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
}

.horizontal-line {
  flex-grow: 1;
  height: 1px;
  background-color: #9291915b;
  margin-left: 10px;
  /* Adjust this value to control the distance between the label and the line */
}

.z-index-0 {
  z-index: 0 !important;
}

.color-white {
  color: white !important;
}

.max-height-150 {
  max-height: 150px !important;
  overflow-y: scroll !important;
}

.text-overflow-wrap {
  text-overflow: wrap;
  white-space: wrap;
}

.demo-toolbar-custom {
  background-color: inherit !important;
  border-color: inherit !important;
  border: 1px solid inherit !important;
  &svg {
    color: inherit !important;
  }
}

.z-index-1000 {
  z-index: 100000000 !important;
}

.iframe {
  width: 100%;
  min-height: 100dvh !important;
}

.image-uploader {
  background: rgb(219, 216, 216);

  .inner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &:hover {
    background-color: rgba(219, 216, 216, 0.473);
  }

  .visibility-none {
    visibility: hidden;
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    width: 100%;
    justify-content: center;

    li {
      position: relative;
      cursor: pointer;
      //transition: transform 0.2s;
      //overflow: hidden;
      width: 150px !important;
      .delete-icon {
        position: absolute;
        top: -10px;
        right: -15px;
        //display: none;
        z-index: 100;
      }

      img {
        width: 100%;
        height: 100px;
      }
      small {
        width: 150;
      }

      &:hover {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
          rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

        /* .delete-icon {
          display: flex;
        } */
      }
    }
  }
}

.border-dashed {
  border: 1px dashed $primary !important;
}

.row-gap-5px {
  row-gap: 5px !important;
  /*  column-gap: 5px !important; */
}

.draft-content {
  font-family: "Inter", sans-serif !important;
  li,
  ul,
  ol,
  p,
  span,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,* {
    font-family: "Inter", sans-serif !important;
    color: rgb(0, 0, 0) !important;
    z-index: 0 !important;
  }
  p{
    font-size: 16px !important;
  }
}

.select__multi-value{
  margin: 5px !important;
}
